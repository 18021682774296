<template>
  <div class="survey_dashboard_container">
    <Statistic group-name="ih"></Statistic>
    <div>
      <div class="survey_dashboard-main">
        <div class="container">
          <div class="row">
            <div class="col-lg-12">
              <div style="padding-bottom: 10px">
                <p style="font-size: 12px">Эх сурвалж: Нийгмийн даатгалын мэдээллийн сан 2022 оны байдлаар.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-lg-12">
              <iframe
                src="https://analytic.edu-track.gov.mn/superset/dashboard/8/?standalone=true"
                frameborder="0"
                style="border: none; width: 100%; height: 100vh"></iframe>
            </div>
          </div>
        </div>
      </div>
    </div>
    <about></about>
  </div>
</template>

<script>
const Statistic = () => import(/* webpackChunkName: "statistic" */ './statistic');
const about = () => import(/* webpackChunkName: "p_about" */ '../section/p_about')
export default {
    name: "UG_ndsh_tulult",
    components: {
        "Statistic": Statistic,
        "about": about,
    }
}
</script>

<style scoped>

</style>
